<template>
  <div class="home">
    <div class="frame">
      <div class="titlebar2">
        <img width="18px" style="margin-right: 3px" src="../assets/aim_icon.png">

        <span class="title_name" style="flex-grow: 1">
        OptShot.com
        </span>

        <img width="15px" src="../assets/minus.png" style="margin-right: 20px;">
        <img width="13px" src="../assets/close.png" style="margin-right: 5px">
      </div>

      <div class="titlebar" style="display: none">
        <!--        <img width="18px" alt="Vue logo" src="../assets/setting.png">-->

        <!--        <span class="name" style="flex-grow: 1">-->
        <!--        OptShot-->
        <!--        </span>-->

        <img width="15px" alt="Vue logo" src="../assets/close.png">
      </div>

      <img class="shadowed_image" alt="Vue logo" src="../assets/show.png">

      <div class="img_button custom_button" style="display: none;">
        <img width="20px" alt="Vue logo" src="../assets/play.png">
        <!--        <i class="el-icon-switch-button"> START (F5)</i>-->
        <span> START (F5)</span>
        <span style="display: none">
          <span class="small_font">(F5)</span>
        </span>
      </div>

      <div class="img_button custom_button">
        <el-button icon="el-icon-switch-button"
                   style="width: 20em"
                   round>START
        </el-button>
      </div>

      <!--      <div style="height: 30px">-->

      <!--      </div>-->


      <div class="setting_wrapper" v-show="is_show_setting">
<!--        <div class="option_line">-->
<!--          <img width="20px" alt="Vue logo" src="../assets/thumbtacks.png">-->
<!--          <span class="desc_text">Window On Top</span>-->
<!--          <el-switch v-model="always_on_top"></el-switch>-->
<!--        </div>-->


        <div class="option_line">
          <img width="20px" alt="Vue logo" src="../assets/click.png">
          <span class="desc_text">Mouse Movement Factor</span>
          <span class="number_input">0.9</span>
        </div>
        <div style="text-align: right;display: none;">
          <el-link style="font-size: small">Auto Calibration</el-link>
        </div>


        <div class="option_line">
          <img width="20px" alt="Vue logo" src="../assets/cuda-svgrepo-com.svg">
          <span class="desc_text">Nvidia CUDA Accelaration</span>
          <el-switch v-model="always_on_top"></el-switch>
        </div>

        <div class="option_line">
          <i class="el-icon-chat-dot-round"></i>
          <span class="desc_text">Feedback</span>
        </div>

        <div class="option_line">
          <i class="el-icon-chat-dot-square"></i>
          <span class="desc_text">Home Page</span>
        </div>
      </div>


      <div class="show_setting">
        <div class="page_down" @click="is_show_setting=!is_show_setting">
          <i class="el-icon-arrow-down" v-show="!is_show_setting"></i>
          <i class="el-icon-arrow-up" v-show="is_show_setting"></i>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      is_show_setting: true,
      always_on_top: true,
      checked: true,
    }
  }
}
</script>

<style scoped>
.title_name {
//font-weight: bold; font-size: small;
}


.desc_text {
  flex-grow: 1;
  font-size: 0.8em;
  color: #33263f;
  font-weight: bold;
}

.option_line img {
  margin-right: 3px;
}

.option_line {
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center; /* 让子元素在垂直方向上居中对齐 */
  margin-top: 10px;
}

.frame {
  padding-top: 2px;
  border-radius: 5px; /* 添加 10px 的圆角 */
  border: 1px gray solid;
  width: 300px;
  position: relative;
  padding-bottom: 30px;
}

.titlebar2 {
  padding: 2px;
  background-color: rgb(240, 243, 248);
  display: flex;
  align-items: center; /* 让子元素在垂直方向上居中对齐 */
}

.titlebar {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  top: 0;
  right: 0;
  position: absolute;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center; /* 让子元素在垂直方向上居中对齐 */
//justify-content: center; /* 让子元素在水平方向上居中对齐 */
}

.img_button {
//border: 1px red solid; display: flex; align-items: center; /* 让子元素在垂直方向上居中对齐 */ justify-content: center; /* 让子元素在水平方向上居中对齐 */
}

.img_button img {
  margin-right: 5px; /* 可以根据需要调整图片与文字之间的间距 */
}

.shadowed_image {
  width: 298px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /*水平偏移量为 0px，垂直偏移量为 4px，模糊半径为 8px，阴影颜色为黑色，透明度为 0.1 */
}

.rounded_image {
  border-radius: 10px; /* 添加 10px 的圆角 */
}


.custom_button:hover {
  color: #4CAF50; /* 设置鼠标悬停时的背景颜色为绿色 */
}

.small_font {
  font-size: 50%; /* 设置小字体大小 */
  color: gray;
}

.show_setting {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
//align-content: center; justify-content: center;
}


.page_down {
  display: block;
  width: 10em;

  text-align: center;
  border-radius: 10px 10px 0 0;
}

.page_down:hover {
  background-color: lightgray;
  color: #42b983;
}

</style>