<template>
  <div class="home">


    <div class="center_container">
      <div class="left_column">
        <div class="main_title">
          OptShot
        </div>
        <h2 class="sub_title">
          The Optimal AI-Aimbot for CS2
        </h2>
        <div class="feature_line_wrapper">
          <div class="feature_line">
            <i class="fas fa-bolt"></i>
            High performance aiming with DXGI & YOLO-V8
          </div>
          <div class="feature_line">
            <i class="fas fa-user-shield"></i>
            Bionic mouse movement for safety
          </div>

          <div class="feature_line">
            <i class="fas fa-laugh-wink"></i>
            Easy to use, with no complicated setting
          </div>

          <div class="feature_line">
            <!--            <i class="fas fa-laugh-wink"></i>-->
            <i class="fas fa-usd-circle"></i>
            <!--            Free trial for 3 days, and get a free license.-->
            <div>
              Free trial for three days, followed by a <br> <span
                class="link_text" @click="openFreeLicense"> free license through promotion</span>
            </div>
          </div>
        </div>

        <div style="height: 60px;">
        </div>
        <div class="btn_wrapper">
          <el-button icon="el-icon-download" round @click="downloadTheSoftWare">Download</el-button>
          <div class="text_btn" @click="onDocumentationClick"><i class="fas fa-book-open"></i>Tutorial</div>
          <div class="text_btn" @click="onDsicrod"><i class="fab fa-discord"></i>Discord</div>
        </div>

      </div>
      <div class="margin1"></div>
      <app-frame v-if="show_frame" @openVideo="openVideo" @close="show_frame=false"/>
    </div>

    <div class="copyright">© 2024 OptShot - All Rights Reserved</div>


    <div id="videoOverlay">
      <div id="videoContainer">
        <span id="closeButton" @click="onCloseIt">&times;</span>
        <iframe class="embed-responsive-item allowfullscreen"
                src="https://www.youtube.com/embed/sUPYTdEVWVY?rel=0&amp;controls=0&amp;showinfo=0"
                allowfullscreen="" frameborder="0" height="415" width="800px">
        </iframe>
      </div>
    </div>

  </div>
</template>

<script>
import AppFrame from "@/components/AppFrame.vue";

export default {
  name: 'HomeView',
  components: {AppFrame},
  data() {
    return {
      show_frame: true,
      dropbox_link: "https://www.dropbox.com/scl/fo/j5t8m00dz67i6fsbrh6v9/h?rlkey=n0ut66dbdy4xq88372eovbu5e&dl=0",
      free_license: "https://doc.optshot.com/5_price.html",
      youtube: "https://youtu.be/sUPYTdEVWVY",
    }
  },
  methods: {
    downloadTheSoftWare() {
      window.open(this.dropbox_link, '_blank');
    },
    onDocumentationClick() {
      window.open('https://doc.optshot.com', '_blank');
    },
    onDsicrod() {
      window.open('https://discord.gg/8pbEQPPZ8U', '_blank');
    },
    openVideo() {
      if (window.innerWidth <= 600) {
        window.open(this.youtube, '_blank');
        // 如果是小屏设备，执行跳转到另一个网页的操作
        // window.location.href = '另一个网页的URL';
      } else {
        // 如果不是小屏设备，执行其他操作，或者不执行任何操作
        document.getElementById("videoOverlay").style.display = "block";
      }
    },
    onCloseIt() {
      document.getElementById("videoOverlay").style.display = "none";
    }
    , openFreeLicense() {
      window.open(this.free_license, '_blank');
    }
  }
}
</script>


<style scoped>

.left_column {
  /*margin-right: 50px;*/
  display: flex;
  flex-direction: column;
}

.home {
  /*background-color: black;*/
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.link_text {
  /*text-decoration: underline;*/
  cursor: pointer;
}

.link_text:hover {
  text-decoration: underline;
}


.main_title {
  font-size: 3em;
  font-weight: bold;
  color: white;


  display: inline-block;
  line-height: 1;
  background: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.sub_title {
  margin-left: 10px;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature_line_wrapper {
  margin-top: 10px;
  color: lightgray;
}

.feature_line {
  color: white;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.feature_line i {
  width: 2em;
  text-align: center;
}

.copyright {
  color: gray;
  position: absolute;
  bottom: 0;
  font-size: small;
}

.text_btn {
  margin-left: 2em;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.text_btn i {
  margin-right: 5px;
}

.text_btn:hover {
  border-bottom: 2px white solid;
}

.btn_wrapper {
  /*margin-bottom: 20px;*/
  display: flex;
  align-items: center;
}


#videoOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明的灰色遮罩 */
  z-index: 9999; /* 确保遮罩位于其他内容之上 */
}

.center_container {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  /*border: 1px red solid;*/
  margin-bottom: 1em;
}

.margin1{
  margin-right: 50px;
}

@media screen and (max-width: 600px) {
  .center_container {
    /*border: 1px red solid;*/
    flex-direction: column; /* 在小屏幕设备上变为竖向排列 */
    align-items: center;
    max-height: 100%;
  /*  100vh 在ios中有问题：https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9 */
  }

  .margin1 {
    margin-right: 0;
    margin-top: 3em;
  }
}


#videoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 24px;
}

</style>