import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Homepage from '../views/Homepage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/v2',
        component: () => import(/* webpackChunkName: "about" */ '../views/V2_NoTitleBar.vue')
    },
    {
        path: '/v3',
        component: () => import(/* webpackChunkName: "about" */ '../views/V3_DarkMode.vue')
    },
    {
        path: '/v4',
        component: () => import(/* webpackChunkName: "about" */ '../views/V4_DarkSimple.vue')
    },
    {
        path: '/v5',
        component: () => import(/* webpackChunkName: "about" */ '../views/Homepage.vue')
    }
]

const routesProd = [
    {
        path: '/',
        component: Homepage
    }
]

const isDev = process.env.NODE_ENV === 'development';

const router = new VueRouter({
    "routes": isDev ? routes : routesProd
})

export default router
