var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"frame"},[_vm._m(0),_vm._m(1),_c('img',{staticClass:"shadowed_image",attrs:{"alt":"Vue logo","src":require("../assets/show.png")}}),_vm._m(2),_c('div',{staticClass:"img_button custom_button"},[_c('el-button',{staticStyle:{"width":"20em"},attrs:{"icon":"el-icon-switch-button","round":""}},[_vm._v("START ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_show_setting),expression:"is_show_setting"}],staticClass:"setting_wrapper"},[_vm._m(3),_c('div',{staticStyle:{"text-align":"right","display":"none"}},[_c('el-link',{staticStyle:{"font-size":"small"}},[_vm._v("Auto Calibration")])],1),_c('div',{staticClass:"option_line"},[_c('img',{attrs:{"width":"20px","alt":"Vue logo","src":require("../assets/cuda-svgrepo-com.svg")}}),_c('span',{staticClass:"desc_text"},[_vm._v("Nvidia CUDA Accelaration")]),_c('el-switch',{model:{value:(_vm.always_on_top),callback:function ($$v) {_vm.always_on_top=$$v},expression:"always_on_top"}})],1),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"show_setting"},[_c('div',{staticClass:"page_down",on:{"click":function($event){_vm.is_show_setting=!_vm.is_show_setting}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_show_setting),expression:"!is_show_setting"}],staticClass:"el-icon-arrow-down"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_show_setting),expression:"is_show_setting"}],staticClass:"el-icon-arrow-up"})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titlebar2"},[_c('img',{staticStyle:{"margin-right":"3px"},attrs:{"width":"18px","src":require("../assets/aim_icon.png")}}),_c('span',{staticClass:"title_name",staticStyle:{"flex-grow":"1"}},[_vm._v(" OptShot.com ")]),_c('img',{staticStyle:{"margin-right":"20px"},attrs:{"width":"15px","src":require("../assets/minus.png")}}),_c('img',{staticStyle:{"margin-right":"5px"},attrs:{"width":"13px","src":require("../assets/close.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titlebar",staticStyle:{"display":"none"}},[_c('img',{attrs:{"width":"15px","alt":"Vue logo","src":require("../assets/close.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_button custom_button",staticStyle:{"display":"none"}},[_c('img',{attrs:{"width":"20px","alt":"Vue logo","src":require("../assets/play.png")}}),_c('span',[_vm._v(" START (F5)")]),_c('span',{staticStyle:{"display":"none"}},[_c('span',{staticClass:"small_font"},[_vm._v("(F5)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option_line"},[_c('img',{attrs:{"width":"20px","alt":"Vue logo","src":require("../assets/click.png")}}),_c('span',{staticClass:"desc_text"},[_vm._v("Mouse Movement Factor")]),_c('span',{staticClass:"number_input"},[_vm._v("0.9")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option_line"},[_c('i',{staticClass:"el-icon-chat-dot-round"}),_c('span',{staticClass:"desc_text"},[_vm._v("Feedback")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option_line"},[_c('i',{staticClass:"el-icon-chat-dot-square"}),_c('span',{staticClass:"desc_text"},[_vm._v("Home Page")])])
}]

export { render, staticRenderFns }