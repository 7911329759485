<template>
  <div class="frame">
    <div class="titlebar2">
        <span class="app_name">
          OptShot
        </span>
      <div style="flex-grow: 1"></div>
      <!--      <i class="fal fa-minus" style="margin-right: 10px"></i>-->
      <i class="far fa-times" @click="onCloseClike"></i>
    </div>

    <div class="img_wrapper">
      <span class="FPS">240 FPS</span>
      <img class="shadowed_image" src="../assets/show.png">
    </div>

    <div class="start_button_wrapper">
      <div class="my_button" style="font-size: 1em;" v-show="!is_started" @click="onStartClick">
        <i class="fab fa-youtube" style="margin-right: 5px;font-size: 1.3em;"></i> Watch Demo
      </div>

      <!--      <div class="my_button" v-show="!is_started" @click="onStartClick">-->
      <!--        <i class="fas fa-play"></i>-->
      <!--        START <span style="font-weight: normal">(F5)</span>-->
      <!--      </div>-->
      <!--      <div class="my_button stop" v-show="is_started" @click="is_started=false">-->
      <!--        <i class="far fa-stop-circle"></i>-->
      <!--        STOP <span style="font-weight: normal">(F5)</span>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFrame',
  props: {},
  data() {
    return {
      is_show_setting: false,
      always_on_top: true,
      checked: true,
      is_started: false,
      switch_active_color: "#96e6a1"
    }
  },
  methods: {
    onStartClick() {
      this.$emit("openVideo")
    },
    onCloseClike() {
      this.$emit("close")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.frame {
  border-radius: 10px; /* 添加 10px 的圆角 */
  /*border: 1px gray solid;*/
  width: 300px;
  box-shadow: 0 4px 8px rgb(254, 254, 254);
  /*水平偏移量为 0px，垂直偏移量为 4px，模糊半径为 8px，阴影颜色为黑色，透明度为 0.1*/
  position: relative;
}


.titlebar2 {
  user-select: none;
  border-radius: 10px 10px 0 0;
  padding: 2px;
  align-items: center; /* 让子元素在垂直方向上居中对齐 */
  display: flex;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  color: white;
}

.titlebar2 i {
  width: 20px;
  text-align: center;
  padding: 3px;
  cursor: pointer;
}

.titlebar2 i:hover {
  background-color: #96e6a1;
  border-radius: 5px;
}

.app_name {
  font-size: small;
  font-weight: normal;
  color: white;
}


.img_wrapper {
  position: relative;
}

.FPS {
  position: absolute;
  left: 3px;
  top: 3px;
  padding: 3px;
  font-size: small;
  /*border: 1px red solid;*/
  background-color: rgba(211, 211, 211, 0.5);
}

.shadowed_image {
  width: 298px;
}


.start_button_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.my_button {
  cursor: default;
  width: 100%;
  text-align: center;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  color: white;
  font-size: small;
  font-weight: bold;
  /*background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);*/
  background-image: linear-gradient(to right, #74ebd5 0%, #9face6 100%);
  transition: 0.3s;
}

.my_button:hover {
  /*color: white;*/
  opacity: 0.9;
  cursor: pointer;
//background-image: linear-gradient(120deg, #96e6a1 0%, #d4fc79 100%); box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}


</style>
